<template>
  <iframe src="https://ghbtns.com/github-btn.html?user=ccfddl&repo=ccf-deadlines&type=star&count=true&size=large" frameborder="0" scrolling="0" width="170" height="30" title="GitHub"></iframe>
</template>

<script>
export default {
  name: 'GithubButton'
}
</script>

<style scoped>

</style>
